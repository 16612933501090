import { template as template_a115e49d9a014141b82be2d86fa57333 } from "@ember/template-compiler";
const FKText = template_a115e49d9a014141b82be2d86fa57333(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
