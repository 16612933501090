import { template as template_c9ee1601aaca4f74b094b44196b8ad2d } from "@ember/template-compiler";
const FKLabel = template_c9ee1601aaca4f74b094b44196b8ad2d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
