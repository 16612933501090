import { template as template_74804fd25daa4646baaeebca1c73bb7e } from "@ember/template-compiler";
const SidebarSectionMessage = template_74804fd25daa4646baaeebca1c73bb7e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
